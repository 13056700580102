import React, { useState } from "react";
// @ts-ignore
import Img from "../assets/img/get-in-touch.png";

import { CompanySize } from "../constant/Common";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import axios from "axios";
import SelectFormik from "../components/formik/SelectFormik";
import InputFormik from "../components/formik/InputFormik";
import MyDatePickerFormik from "../components/formik/MyDatePickerFormik";
import TextareaFormik from "../components/formik/TextareaFormik";

const GetInTouch = () => {
  const validationSchema = Yup.object().shape({
    location: Yup.string().required("First name is required"),
    howManyPeople: Yup.object().required("Required"),
    date: Yup.string().required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Work email is required"),
  });
  const showToast = () => {
    toast.success("Success!", {
      position: "top-center",
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSubmit = async (
    data: FormikValues,
    actions: FormikHelpers<any>
  ) => {
    console.log(data);
    const body = {
      topic: "Landing page: Workshop",
      data: {
        location: data?.location,
        howManyPeople: data?.howManyPeople?.value,
        date: data?.date,
        objective: data?.objective,
        length: data?.length,
        context: data?.context,
        budget: data?.budget,
        email: data?.email,
      },
    };
    console.log(data, body);
    try {
      const response = await axios.post(
        // "http://localhost:5000/api/sns/pass-to-coachello",
        "https://api.coachello.io/api/sns/pass-to-coachello",
        body
      );

      // Handle the response as needed (e.g., show a success message)
      console.log("API success:");
      showToast();
      // Clear the form after successful submission
      actions.resetForm();
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("Error submitting the form:", error);
    }
  };
  return (
    <div className="section bg-warm">
      <div className="flex gap-10 flex-col md:flex-row">
        <div className="lg:w-1/2">
          <div className="">
            <p className="red-heading">Get in touch</p>
            <h2 className="mt-3 mb-10">
              I would like to discuss a workshop need:
            </h2>
          </div>
          <img className="w-full object-contain" src={Img} />
        </div>
        <Formik
          initialValues={{
            location: "",
            howManyPeople: "",
            date: "",
            objective: "",
            length: "",
            context: "",
            budget: "",
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="p-5 flex flex-col gap-5 lg:w-1/2">
            <InputFormik name="location" label="Location" />
            <div className="flex gap-5 flex-col md:flex-row">
              <SelectFormik
                name="howManyPeople"
                className="flex-1"
                options={CompanySize}
                label="How many people"
              />
              <MyDatePickerFormik name="date" label="Date" />
            </div>
            <TextareaFormik name="objective" label="Objective" />
            <InputFormik name="length" label="Length" type="text" />
            <TextareaFormik name="context" label="Context" />
            <div className="flex gap-5 flex-col md:flex-row">
              <InputFormik name="budget" type="number" label="Budget" />
              <InputFormik name="email" label="Email" type="email" />
            </div>

            <button
              type="submit"
              className="dashboard-button bg-black rounded-full mr-auto mt-5"
            >
              Get free quote
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default GetInTouch;
